import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import {
  AiOutlineDoubleLeft,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDoubleRight,
} from 'react-icons/ai';

const TablePaginationWidget = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  pageCount,
}) => {
  const pageSizeArray = [20, 50, 100];

  return (
    <Row className="text-right">
      <Col>
        <button
          className="btn btn-outline-primary"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <AiOutlineDoubleLeft />
        </button>{' '}
        <button
          className="btn btn-outline-primary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <AiOutlineArrowLeft />
        </button>{' '}
        <button
          className="btn btn-outline-primary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <AiOutlineArrowRight />
        </button>{' '}
        <button className="btn btn-outline-primary">
          <AiOutlineDoubleRight
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          />
        </button>{' '}
        Page {pageIndex + 1} of {pageOptions.length} | Go to page:{' '}
      </Col>
      <Row>
        <Col md={3}>
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '80px' }}
          />
        </Col>
        <Col md={3}></Col>
        <Col md={6}>
          <Input
            type="select"
            className="rounded-0"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeArray.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
    </Row>
  );
};

export default TablePaginationWidget;
