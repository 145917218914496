import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuthContext } from "utils/contexts";

export default function ProtectedRoute({ component: Component, authenticate, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAuthContext();

  if(authenticate) {
    return (
      <Route {...rest}
        render={ props => isAuthenticated ? (
          <Component { ...props } />
        ) : (
          <Redirect to={
            `/login?redirect=${pathname}${search}`
          } />
        )}
      />
    );
  }
  else {
    let redirect;
    if(search) {
      redirect = new URLSearchParams(search).get("redirect");
    }
    return (
      <Route {...rest}
        render={ props => !isAuthenticated ? (
          <Component { ...props } />
        ) : (
          <Redirect to={ redirect ? redirect : "/" } />
        )}
      />
    );
  }
}

ProtectedRoute.defaultProps = {
  authenticate: true
}